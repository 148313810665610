
import {Component, Ref, Vue} from "vue-property-decorator";
import CustomFormDataTableDetail from "@/components/CustomForm/TableDetail/index.vue";
import {
  AttachmentHostType,
  AuditFlowScope,
  CustomFormType, OrganizationUnitDto, ProjectBeneficiaryMappingDto,
  ProjectServiceItemDto,
  ServiceRecordType
} from "@/api/appService";
import api from '@/api/index';
import {AuditBodyDto} from "@/utils/customDto";
import ExportWord from "@/components/DownloadWord/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import AuditButtons from "@/components/AuditButtons/index.vue";
import FileList from "@/components/CustomForm/TableDetail/components/fileList.vue";

@Component({
  name: 'ServiceRecordDetail',
  components: {
    CustomFormDataTableDetail,
    ExportWord,
    AttachmentsView,
    AuditButtons,
    FileList
  },
  filters: {
    formatCompleteStatus(status: string, completeStatusList: any[]) {
      let value: string | undefined
      if (completeStatusList && completeStatusList.length > 0) {
        completeStatusList.map((item: any) => {
          if (item.value == status) {
            value = item.text
            return
          }
        })
      }
      return value
    }
  }
})
export default class ServiceRecordDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  type: ServiceRecordType = ServiceRecordType.School;
  private types = ServiceRecordType;
  private formId!: string;
  private dataId!: string;
  private hostType: CustomFormType = CustomFormType.BeneficiaryServiceRecord;
  private isProject = false;
  isReady = false;
  serviceItems: ProjectServiceItemDto[] = [];
  beneficiary: ProjectBeneficiaryMappingDto = {}
  organization: OrganizationUnitDto = {}
  // 达成情况
  completeStatusList = [];

  styles: string = "";
  selector: string = "service-record-detail"
  filename: string = "服务记录表";
  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.ServiceRecord,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  }

  get hostTypeFile() {
    return AttachmentHostType.Internal_ServiceRecord;
  }

  created() {
    this.fetchEnum();
    this.formId = this.$route.query.formId as string;
    this.dataId = this.$route.query.id as string;
    this.isProject = false;

    if (this.dataId && this.hostType) {
      this.isReady = true;
    } else {
      this.$message.error('缺少url参数 id 或 hostType，请检查!');
    }

    this.fetchAuditDetail();
  }

  fetchEnum() {
    api.enumService.getValues({typeName: 'ProjectServiceRecordCompleteStatus'}).then((res: any) => {
      this.completeStatusList = res
    });
  }

  fetchAuditDetail() {
    api.beneficiaryServiceRecord.get({id: parseInt(this.dataId)}).then(res => {
      this.type = this.$route.query.type as ServiceRecordType;
      if (this.type === ServiceRecordType.School) {
        this.hostType = CustomFormType.SchoolServiceRecord;

        api.organizationUnit.get({id: res.schoolId}).then(res => {
          this.organization = res;
        })
      } else {
        this.hostType = CustomFormType.BeneficiaryServiceRecord;

        api.beneficiary.get({id: res.beneficiaryId}).then(res => {
          this.beneficiary = res;
        })
      }

      this.serviceItems = res.serviceItems as ProjectServiceItemDto[];
      if (this.serviceItems) {
        this.serviceItems.map(item => {
          item.serviceMethod = item.serviceMethods?.join(',')
        })
      }
      this.auditParams!.hostId = res.id + ''
      this.auditParams!.auditFlowId = res.auditFlowId;
      this.auditParams!.auditStatus = res.auditStatus;
      this.auditParams!.audit = res.audit;
      if (res.auditFlowId) {
        (this.auditButtons as any).fetchCanAudit()
      }
    })
  }

  getData() {
    return api.beneficiaryServiceRecord.get({id: parseInt(this.dataId)});
  }

  getHostType() {
    return this.hostType;
  }

  // 返回
  private cancel() {
    this.$router.back();
  }

  fetchData() {
    return api.beneficiaryServiceRecord.audit;
  }

  changeAudit() {
    this.fetchAuditDetail()
  }
}
