
import {Component, Vue} from "vue-property-decorator";
import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";
import {
  AttachmentHostType,
  CustomFormType,
  OrganizationUnitDto,
  ProjectBeneficiaryMappingDto,
  ProjectServiceItemCreateOrUpdateDto,
  ServiceRecordCreateOrUpdateDto,
  ServiceRecordType,
} from "@/api/appService";
import api from "@/api";
import AbSelect from "@/components/AbSelect/index.vue";
import OrganizationSelect from "@/components/OrganizationSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";

@Component({
  name: "CreateInternalProjectBeneficiary",
  components: {
    CustomFormEdit,
    AbSelect,
    OrganizationSelect,
    MultipleUploadFile,
  },
})
export default class CreateInternalProjectBeneficiary extends Vue {
  dataId: number = 0;
  projectId!: number;
  formId!: number;
  isShow: boolean = false;
  canChange: boolean = false;
  beneficiary: ProjectBeneficiaryMappingDto = {};
  serviceItems: ProjectServiceItemCreateOrUpdateDto[] = [];
  organization: OrganizationUnitDto = {};
  completeStatus = [];
  serviceMethod = [];
  organizationType: number = 0;

  created() {
    this.fetchEnum();
    this.fetchDataDictionary();
    // 自定义表单id
    if (this.$route.query.formId) {
      this.formId = Number(this.$route.query.formId);
    }
    // 内部项目id
    if (this.$route.query.projectId) {
      this.projectId = Number(this.$route.query.projectId);
    }

    if (this.$route.query.id) {
      this.canChange = true;
      this.dataId = Number(this.$route.query.id);
      api.beneficiaryServiceRecord.get({id: this.dataId}).then((res) => {
        this.organization.id = res.schoolId;
        this.isShow = true;
        this.serviceItems = res.serviceItems ?? [];
        if (this.serviceItems && this.serviceItems!.length > 0) {
          this.serviceItems.map((item) => {
            item.savedAttachments = item.savedAttachments ?? [];
          });
        }
      });
    }
  }

  get hostType() {
    return CustomFormType.SchoolServiceRecord;
  }

  chooseSchool() {
    this.isShow = false;
    api.organizationUnit.get({id: this.organization.id}).then((res) => {
      this.organization = res;
      this.isShow = true;
    });
  }

  get getData() {
    if (!this.dataId) {
      return;
    } else {
      return api.beneficiaryServiceRecord.get({id: this.dataId});
    }
  }

  get hostTypeFile() {
    return AttachmentHostType.Internal_ServiceRecord;
  }

  fetchEnum() {
    api.enumService
      .getValues({typeName: "ProjectServiceRecordCompleteStatus"})
      .then((res: any) => {
        this.completeStatus = res;
      });
  }

  fetchDataDictionary() {
    api.dataDictionary
      .getDataDictionaryListByKey({
        key: "ServiceMethod",
        maxResultCount: 1000,
      })
      .then((res: any) => {
        this.serviceMethod = res.items;
      });
    api.dataDictionary
      .getDataDictionaryListByKey({
        key: "OrganizationUnitType",
        maxResultCount: 1000,
      })
      .then((res: any) => {
        if (res.items)
          res.items.map((item: any) => {
            if (item.key && item.key === "学校") {
              this.organizationType = item.id;
              return;
            }
          });
      });
  }

  handleSave() {
    (this.$refs.customForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (!this.organization.id) {
          this.$message.error("请先选择组织");
          return;
        }
        const data = (this.$refs
          .customForm as any).getRequestData() as ServiceRecordCreateOrUpdateDto;
        data.projectId = this.projectId;
        data.schoolId = this.organization.id;
        data.serviceRecordType = ServiceRecordType.School;
        data.serviceItems = this.serviceItems;
        data.formId = this.formId; // formId必传

        if (this.dataId) {
          api.beneficiaryServiceRecord.update({body: data}).then(() => {
            this.$message.success("更新成功");
            this.$router.back();
          });
        } else {
          data.id = 0;
          api.beneficiaryServiceRecord.create({body: data}).then(() => {
            this.$message.success("新建成功");
            this.$router.back();
          });
        }
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  //增加服务记录
  private addRow(r: number) {
    const obj = {
      id: undefined,
      serviceTime: undefined,
      serviceMethod: undefined,
      serviceContent: undefined,
      target: undefined,
      completeStatus: undefined,
      remark: undefined,
      savedAttachments: [],
    };

    this.serviceItems = [...this.serviceItems!, obj];
  }

  // 删除服务记录
  private deleteRow(index: number) {
    this.serviceItems!.splice(index, 1);
  }
}
